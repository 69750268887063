import ErpWarehouseList from "./list";
import ErpWarehouseShow from "./show";


if (document.body.getAttribute("data-current-path") === "erp/warehouses") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new ErpWarehouseList();
    } else if(action === "show") {
        new ErpWarehouseShow();
    }
}